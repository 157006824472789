export const Constants = {
  ROWS_PER_PAGE: 10,
  CONTENT_TYPE_VIEW_LIST: ["Patient Leaflets", "Research Papers"],
  USER_ROLES: ["SA","TA","CM","CA"],
  USER_ADD_SUCCESS: "User has been created successfully.",
  USER_PROFILE_UPDATE_SUCCESS: "User profile has been updated successfully.",
  USER_PROFILE_UPDATE_FAILED: "Failed to update user profile",
  USER_PASSWORD_UPDATE_SUCCESS: "Password updated successfully",
  USER_PASSWORD_MISMATCH: "Password mismatch",
  USER_PASSWORD_UPDATE_FAILED: "Failed to update password",
  USER_REMOVE_SUCCESS: "User has been removed successfully.",
  RESET_LINK_SENT_SUCCESS: "Reset link for password has been sent.",
  TRUST_ADD_SUCCESS: "Trust created successfully.",
  LEVEL1_ADD_SUCCESS: "System admin created successfully.",
  REVIEW_ADD_SUCCES: "Resource has been sent back to the resource creator.",
  CONTENT_APPROVED: "You have approved this resource.",
  RESOURCE_CONTENT_SAVED: "Resource content saved successfully.",
  RESOURCE_CONTENT_CREATED: "Your resource has been created successfully",
  RESOURCE_CONTENT_UPDATED: "Content of your resource has been updated successfully.",
  RESOURCE_CONTENT_UPDATED_AND_SFA: "Your resource has been updated successfully and has been sent for approval.",
  RESOURCE_CONTENT_UPDATED_AND_APPROVED: "Content of your resource has been updated and approved successfully.",
  CONTENT_TOPIC_SUCCESS: "Content topic has been saved successfully.",
  RESOURCE_TYPE_UPDATED: "Resource type updated successfully.",
  RESOURCE_TYPE_APPROVED: "Resource type approved successfully.",
  RESOURCE_TYPE_DISAPPROVED: "Resource type disapproved successfully.",
  RESOURCE_TYPE_DELETED: "Resource type deleted successfully.",
  RESOURCE_TOPIC_UPDATED: "Resource topic updated successfully.",
  RESOURCE_TOPIC_CREATED: "Your resource topic has been created successfully",
  RESOURCE_TOPIC_DELETED: "Resource topic deleted successfully.",
  RESOURCE_TOPIC_APPROVED: "Resource topic approved successfully.",
  RESOURCE_TOPIC_DISAPPROVED: "Resource topic disapproved successfully.",
  TRUST_DELETED: "Trust archived successfully.",
  SYSTEM_ADMIN_DELETED: "System admin archived successfully.",
  USER_DELETED: "User archived successfully.",
  EMAIL_ALREADY_EXIST: "Email address already exists!",
  CONTENT_TYPE_STATIC_LIST: [ "Apps", "Patient Leaflets", "Tips", "Articles", "Research Papers", "Useful links", "Events & Groups", "Stories", "Videos", "Workbooks"],
}