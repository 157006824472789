import React from 'react';

import './loading.scss';

export interface LoadingProps {
  status?: boolean;
}

const Loading: React.FC<LoadingProps> = (props) => {
  const { status } = props;

  return (
    <>
      {status && (
        <div className='loading-wrapper'>
          <svg className='spinner' viewBox='0 0 50 50'>
            <circle
              className='path'
              cx='25'
              cy='25'
              r='20'
              fill='none'
              stroke-width='5'
            ></circle>
          </svg>
        </div>
      )}
    </>
  );
};

export default Loading;
