import React from 'react';
import { getRandomText } from '../global/HelperFunctions';

interface SearchProps {
  handleSearchChange: ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => void;
  id?: any;
}

export const Search: React.FC<SearchProps> = ({
  handleSearchChange,
  id = getRandomText(10),
}) => {
  return (
    <div className='col-md-6 col-12 search-container'>
      <div className='col-12'>
        <div className='search-box'></div>
        <label htmlFor={id} className='sr-only'>
    
        </label>
        <input
          id={id}
          className='search-input'
          placeholder='Search'
          onChange={handleSearchChange}
          type='text'
        />
      </div>

      <div className={`secondary-text fw-bold search-transition`}>
        {/* <button className="btn btn-green">
                SEARCH
              </button> */}
      </div>
    </div>
  );
};

/* OLD SEARCH COMPONENTS */

{
  /* <div className="col-md-6 col-12 search-container">
            {showSearch && (
              <div className="col-md-8 col-12">
                <input className="search-input" onChange={handleSearchChange} />
              </div>
            )}
            <div
              className={`secondary-text fw-bold search-transition ${showSearch ? "col-4 search-btn" : "col-12"
                }`}
            >
              <button className="btn btn-green" onClick={toggleSearch}>
                SEARCH
              </button>
            </div>
          </div> */
}
