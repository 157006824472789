import React from "react";

import { ReactComponent as Manage_accounts } from "../../assets/images/manage_accounts.svg";
import { ReactComponent as Trust_Management } from "../../assets/images/Trust_Management.svg";
import { ReactComponent as ResourceList } from "../../assets/images/list.svg";
import { ReactComponent as NewResource } from "../../assets/images/create.svg";
import { ReactComponent as Notification } from "../../assets/images/notifications.svg";
import { ReactComponent as Resource } from "../../assets/images/Resources.svg";

export const MobileBentomenu: any = [
  {
    title: "Trust management",
    link: "/trust",
    iconsrc: <Trust_Management />,
    role: ["SA"],
  },
  {
    title: "User Management",
    link: "/user-management",
    iconsrc: <Manage_accounts />,
    role: ["SA", "TA"],
  },
  {
    title: "Current Resource List",
    link: "/resource",
    iconsrc: <ResourceList />,
    role: ["TA", "CM", "CA"],
  },
  {
    title: "Create New Resource",
    link: "/resource/create",
    iconsrc: <NewResource />,
    role: ["CM"],
  },
  {
    title: "My Notifications",
    link: "/notification-center",
    iconsrc: <Notification />,
    role: ["TA", "CM", "CA"],
  },
  {
    title: "Resource Library",
    link: "/resource-library",
    iconsrc: <Resource />,
    role: ["TA"],
  },
];
