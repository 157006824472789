export const APIDoc = [
  {
    name: "Authentication",
    desc: [
      // {
      //   title: "What it is:",
      //   desc: "This section has API's to authenticate system admin by giving client id, client secret id and regenreate token",
      // },
      {
        title: "Authentication",
        desc: "This API validates the system admin who’s trying to connect with the Triangle system by providing them the client ID, client secret and regenerate token.",
      },
    ],
    APIs: {
      mainTitle: "Please select an API:",
      APIList: [
        {
          name: "Get access token",
          title: "‘Get access token’ API",
          desc: "This API is  used to provide the access token based on the client id and client secret id and returns token in a JSON format.",
          urlTitle: "Base URL",
          urlDesc:
            "All requests to this API should start with the following base URL:",
          url:  "/api/oauth/accesstoken?",
          methodType: "GET",
          APIVersionTitle: "API version",
          APIVersionDesc:
            "The API version number can be supplied as a query parameter:",
          APIversion: "?api-version=",
          authAndHeaderTitle: "Authentication and headers",
          authAndHeaderDesc:
            "Your request must include a subscription-key header with a valid subscription key.",
          authAndHeader: {
            tableHeading: ["Header Key", "input", "clientId"],
            tableData: [
              "Header Value",
              "Loginor register to get an API key to use this API",
            ],
          },
          queryParams: ["clientId", "clientSecret"],
          bodyTitle: "Body",
          body: false,
          responsesTitle: "Responses",
          sampleResponses: {
            status: true,
            message: "Access Token Generated",
            data: {
              accessToken:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9eyJvQXV0aF9pZCI6IjYyZWJjMTA0ZTc4Y2QxNzYxNWU5MDM1MyIsImNsaWVudElkIjoiYjBiZWMzNmI2ZGQzMGU2MSIsImlhdCI6MTY1OTYzMDE2NiwiZXhwIjoxNjU5NzE2NTY2fQ.B9m5tPHWgeIzWDp1xhInjdrNi91D2Vd9XXatkeg4A_I",
              refreshToken: "49069b5c2d2a8c9b79d47e1d",
              validUpto: "2022-08-05T16:22:46.000Z",
            },
          },
        },
        {
          name: "Refresh token",
          title: "‘Refresh token’ API",
          desc: "This API is  used to refresh the access token with existing token and returns  new token in a JSON format.",
          urlTitle: "Base URL",
          urlDesc:
            "All requests to this API should start with the following base URL:",
          url: process.env.REACT_APP_SERVICE_PHR + "/api/oauth/regenerate?",
          methodType: "GET",
          // APIVersionTitle: "API version",
          // APIVersionDesc:
          //   "The API version number can be supplied as a query parameter:",
          // APIversion: "?api-version=",
          authAndHeaderTitle: "Authentication and headers",
          authAndHeaderDesc:
            "Your request must include a subscription-key header with a valid subscription key.",
          authAndHeader: {
            tableHeading: ["Header Key", "input", "clientId"],
            tableData: [
              "Header Value",
              "Loginor register to get an API key to use this API",
            ],
          },
          queryParams: ["refreshToken"],
          bodyTitle: "Body",
          body: false,
          responsesTitle: "Responses",
          sampleResponses: {
            status: true,
            message: "Access Token Regenerated",
            data: {
              accessToken:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9eyJvQXV0aF9pZCI6IjYyZWJjMTA0ZTc4Y2QxNzYxNWU5MDM1MyIsImNsaWVudElkIjoiYjBiZWMzNmI2ZGQzMGU2MSIsImlhdCI6MTY1OTYzMDE2NiwiZXhwIjoxNjU5NzE2NTY2fQ.B9m5tPHWgeIzWDp1xhInjdrNi91D2Vd9XXatkeg4A_I",
              refreshToken: "49069b5c2d2a8c9b79d47e1d",
              validUpto: "2022-08-05T16:22:46.000Z",
            },
          },
        },
      ],
    },
  },
  {
    name: "Resources",
    desc: [
      // {
      //   title: "What it is:",
      //   desc: "This is section which is having all API's related resource module",
      // },
      {
        title: "Resources",
        desc: "The APIs under this section will fetch your inputs/results for filters, resource type, resource count, age group and tags.",
      },
    ],
    APIs: {
      mainTitle: "Please select an API:",
      APIList: [
        {
          name: "Get all filters",
          title: "‘Get all filters’ API",
          desc: "This API used to fetch a filter popup data which is created under selected system admin in the authentication module",
          urlTitle: "Base URL",
          urlDesc:
            "All requests to this API should start with the following base URL:",
          url:
            process.env.REACT_APP_SERVICE_PHR + "/api/oauth/all-filter-types?",
          methodType: "GET",
          APIVersionTitle: "API version",
          APIVersionDesc:
            "The API version number can be supplied as a query parameter:",
          APIversion: "?api-version=",
          authAndHeaderTitle: "Authentication and headers",
          authAndHeaderDesc:
            "Your request must include a subscription-key header with a valid subscription key.",
          authAndHeader: {
            tableHeading: ["Header Key", "input", "clientId"],
            tableData: [
              "Header Value",
              "Loginor register to get an API key to use this API",
            ],
          },
          queryParams: ["ageGroup", "type"],
          bodyTitle: "Body",
          body: false,
          responsesTitle: "Responses",
          sampleResponses: {
            status: true,
            message: "allFilters",
            data: {
              pagination: {
                perPage: 5,
                page: 1,
                total: 4,
                nowFetched: 4,
              },
              data: {
                selectedTags: [],
              },
            },
          },
        },
        {
          name: "Get resources list",
          title: "‘Get resources list’ API",
          desc: "This API used to fetch a resources which is created under selected system admin in the authentication module.",
          urlTitle: "Base URL",
          urlDesc:
            "All requests to this API should start with the following base URL:",
          url: process.env.REACT_APP_SERVICE_PHR + "/api/oauth/tp/getResources?",
          methodType: "POST",
          APIVersionTitle: "API version",
          APIVersionDesc:
            "The API version number can be supplied as a query parameter:",
          APIversion: "?api-version=",
          authAndHeaderTitle: "Authentication and headers",
          authAndHeaderDesc:
            "Your request must include a subscription-key header with a valid subscription key.",
          authAndHeader: {
            tableHeading: ["Header Key", "Header Value"],
            tableData: [
              "subscription-key",
              "Loginor register to get an API key to use this API",
            ],
          },
          queryParams: {
            page: 1,
            perPage: 5,
            sortBy: "title",
            sortOrder: -1,
            filter: { tags: "Health,Sugar" },
          },
          bodyTitle: "Body",
          body: true,
          responsesTitle: "Responses",
          sampleResponses: {
            status: true,
            message: "Content list",
            data: {
              pagination: {
                perPage: 5,
                page: 1,
                total: 4,
                nowFetched: 4,
              },
              content: [],
            },
          },
        },

        {
          name: "Get resources count by type",
          title: "‘Get resources count by type’ API",
          desc: "This API user fetch a count of resources based on the resources type.",
          urlTitle: "Base URL",
          urlDesc:
            "All requests to this API should start with the following base URL:",
          url:
            process.env.REACT_APP_SERVICE_PHR +
            "/api/oauth/get-resources-count-type?",
          methodType: "GET",
          APIVersionTitle: "API version",
          APIVersionDesc:
            "The API version number can be supplied as a query parameter:",
          APIversion: "?api-version=",
          authAndHeaderTitle: "Authentication and headers",
          authAndHeaderDesc:
            "Your request must include a subscription-key header with a valid subscription key.",
          authAndHeader: {
            tableHeading: [],
            tableData: [],
          },
          queryParams: {
            page: 1,
            perPage: 5,
            sortBy: "title",
            sortOrder: -1,
          },
          bodyTitle: "Body",
          body: false,
          responsesTitle: "Responses",
          sampleResponses: {
            status: true,
            message: "Count of resources for each type",
            data: [
              {
                count: 12,
                resource_type: "Apps",
              },
            ],
          },
        },
      ],
    },
  },
  {
    name: "Trust management",
    desc: [
      // {
      //   title: "What it is:",
      //   desc: "This contains all API's related to trust management such as create trust,update trust and get all trust list",
      // },
      {
        title: "Trust management",
        desc: "These APIs will allow to create, update and manage Trusts. They also allow you to get the list of Trusts connected with Triangle.",
      },
    ],
    APIs: {
      mainTitle: "Please select an API:",
      APIList: [
        {
          name: "Create Trust",
          title: "‘Create Trust’ API",
          desc: "This API used to create a trust and we need to pass name, email and trust id from the integrated platform",
          urlTitle: "Base URL",
          urlDesc:
            "All requests to this API should start with the following base URL:",
          url: process.env.REACT_APP_SERVICE_PHR + "/api/oauth/create-trust",
          methodType: "POST",
          APIVersionTitle: "API version",
          APIVersionDesc:
            "The API version number can be supplied as a query parameter:",
          APIversion: "?api-version=",
          authAndHeaderTitle: "Authentication and headers",
          authAndHeaderDesc:
            "Your request must include a subscription-key header with a valid subscription key.",
          authAndHeader: {
            tableHeading: ["Header Key", "input", "clientId"],
            tableData: [
              "Header Value",
              "Loginor register to get an API key to use this API",
            ],
          },
          queryParams: {
            name: "Demo trust asdsi",
            email: "thiru+demoAPITrust@mindwaveventures.com",
            trustId: "e37d9a87-43d5-4901-ac57-db4184d6c492",
          },

          bodyTitle: "Body",
          body: true,
          responsesTitle: "Responses",
          sampleResponses: {
            message: "Trust created successfuly",
            data: {
              trustId: "62efda489b0e53a52333811f",
            },
          },
        },
        {
          name: "Update trust",
          title: "‘Update trust’ API",
          desc: "This API is used to update the trust info name and archieve/unarchieve.",
          urlTitle: "Base URL",
          urlDesc:
            "All requests to this API should start with the following base URL:",
          url: process.env.REACT_APP_SERVICE_PHR + "/api/oauth/update-trust",
          methodType: "post",
          APIVersionTitle: "API version",
          APIVersionDesc:
            "The API version number can be supplied as a query parameter:",
          APIversion: "?api-version=",
          authAndHeaderTitle: "Authentication and headers",
          authAndHeaderDesc:
            "Your request must include a subscription-key header with a valid subscription key.",
          authAndHeader: {
            tableHeading: ["Header Key", "Header Value"],
            tableData: [
              "subscription-key",
              "Loginor register to get an API key to use this API",
            ],
          },
          queryParams: {
            trustId: "e37d9a87-43d5-4901-ac57-db4184d6c492",
            name: "Demo Trust OauthAPIt",
            isActive: "false",
          },
          bodyTitle: "Body",
          body: true,
          responsesTitle: "Responses",
          sampleResponses: {
            status: true,
            message: "Trust info updated",
            data: {},
          },
        },
        {
          name: "Get all Trusts",
          title: "‘Get all trusts’ API",
          desc: "This API is used to fetch all trust which is under system admin",
          urlTitle: "Base URL",
          urlDesc:
            "All requests to this API should start with the following base URL:",
          url: process.env.REACT_APP_SERVICE_PHR + "/api/oauth/get-all-trusts",
          methodType: "GET",
          APIVersionTitle: "API version",
          APIVersionDesc:
            "The API version number can be supplied as a query parameter:",
          APIversion: "?api-version=",
          authAndHeaderTitle: "Authentication and headers",
          authAndHeaderDesc:
            "Your request must include a subscription-key header with a valid subscription key.",
          authAndHeader: {
            tableHeading: [],
            tableData: [],
          },
          queryParams: [],
          bodyTitle: "Body",
          body: true,
          responsesTitle: "Responses",
          sampleResponses: {
            status: true,
            message: "All trusts fetched successfully",
            data: {
              trusts: [
                {
                  _id: "62e0fecf3e3ba27109a37622",
                  name: "Demo Trust A",
                  contact: {
                    email: "thiru+dta@mindwaveventures.com",
                  },
                  isActive: true,
                },
                {
                  _id: "62e0feed3e3ba27109a37630",
                  name: "Demo Trust B",
                  contact: {
                    email: "thiru+dtb@mindwaveventures.com",
                  },
                  isActive: true,
                },
                {
                  _id: "62e0fefd3e3ba27109a37644",
                  name: "Demo Trust C",
                  contact: {
                    email: "thiru+dtc@mindwaveventures.com",
                  },
                  isActive: true,
                },
                {
                  _id: "62eb3cdcb96961d294dd7b4d",
                  name: "Demo Trust API",
                  contact: {
                    email: "thiru+demoAPITrust@mindwaveventures.com",
                  },
                  isActive: false,
                },
                {
                  _id: "62eb5350b96961d294dd7ba5",
                  name: "Demo trust api with trustid",
                  contact: {
                    email: "thiru+trustid@mindwaveventures.com",
                  },
                  isActive: true,
                },
                {
                  _id: "62ebcb35d0a8195eead225ed",
                  name: "Thiruadmindf",
                  contact: {
                    email: "thiru+1dfdsaf@mindwaveventures.com",
                  },
                  isActive: true,
                },
                {
                  _id: "62efda489b0e53a52333811f",
                  name: "Demo Trust OauthAPIt",
                  oauthTrustId: "e37d9a87-43d5-4901-ac57-db4184d6c492",
                  contact: {
                    email: "thiru+demoAPITrustds@mindwaveventures.com",
                  },
                  isActive: false,
                },
              ],
            },
          },
        },
      ],
    },
  },
];
