import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../app/store";

export const createResource = createAsyncThunk(
  "resource/create",
  async ({ resource, formData }: any, thunkAPI) => {
    try {
      let fileUploadResult: any;
      if (formData) {
        const response = await axios({
          method: "POST",
          url: "/api/content/file-upload",
          data: formData,
        });
        fileUploadResult = await response.data;
      }
      if (fileUploadResult?.data?.file) {
        resource.uploaded = true;
        resource.file = fileUploadResult.data.file;
        resource.filePath = fileUploadResult.data.filePath;
      }
      const response = await axios({
        method: "POST",
        url: "/api/content",
        data: resource,
      });
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("🚀 ~ file: ResourceSlice.ts ~ line 35 ~ e", e)
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const uploadFile = createAsyncThunk(
  "resource/fileUpload",
  async ({ formData }: any, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: "/api/content/file-upload",
        data: formData,
      });
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateResource = createAsyncThunk(
  "resource/update",
  async ({ resource, formData }: any, thunkAPI) => {
    let fileUploadResult: any = {};
    if (formData) {
      const response = await axios({
        method: "POST",
        url: "/api/content/file-upload",
        data: formData,
      });
      fileUploadResult = await response.data;
    }
    if (fileUploadResult?.data?.file) {
      resource.uploaded = true;
      resource.file = fileUploadResult.data.file;
      resource.filePath = fileUploadResult.data.filePath;
    }
    try {
      const response = await axios({
        method: "PUT",
        url: `/api/content/${resource._id}`,
        data: resource,
      });
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getResource = createAsyncThunk(
  "resource/get",
  async (id: any, thunkAPI) => {
    try {
      const response = await axios({
        method: "GET",
        url: `/api/content/${id}`,
      });
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getResourceList = createAsyncThunk(
  "resource/getlist",
  async (query: any, thunkAPI) => {
    try {
      let url = `/api/content?sortBy=${query.sortBy}&sortOrder=${query.sortOrder}&page=${query.page}&perPage=${query.perPage}`;
      if (query.searchString) {
        url = `${url}&searchString=${query.searchString}`;
      }
      if (query.filter && Object.keys(query.filter).length > 0) {
        url = `${url}&filter=${JSON.stringify(query.filter)}`;
      }
      const response = await axios({
        method: "GET",
        url: url,
        data: query,
      });
      let data = await response.data;
      console.log("🚀 ~ file: ResourceSlice.ts ~ line 137 ~ data", data)
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e);
      thunkAPI.rejectWithValue(e);
    }
  }
);

export const getResourceType = createAsyncThunk(
  "resource-type/get",
  async (id: any, thunkAPI) => {
    try {
      const response = await axios({
        method: "GET",
        url: `/api/resource-types`,
      });
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getResourceTypeWithPaging = createAsyncThunk(
  "resource-type-paging/get",
  async (query: any, thunkAPI) => {
    try {
      let url = `/api/resource-types/with-paging?sortBy=${query.sortBy}&sortOrder=${query.sortOrder}&page=${query.page}&perPage=${query.perPage}`;
      const response = await axios({
        method: "GET",
        url: url,
      });
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getResourceTopic = createAsyncThunk(
  "resource-topic/get",
  async (id: any, thunkAPI) => {
    try {
      let url = `/api/resource-topics`;
      if(id && id > 1){
        url = `/api/resource-topics?id=`+id;
      }
      const response = await axios({
        method: "GET",
        url: url,
      });
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getResourceTopicById = createAsyncThunk(
  "resource-topic/get",
  async (topicId: any, thunkAPI) => {
    try {
      let url = `/api/resource-topics/${topicId}`;
      const response = await axios({
        method: "GET",
        url: url,
      });
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateResourceTopic = createAsyncThunk(
  "resource-topic/update",
  async ({topicId, updatedData}: any, thunkAPI) => {
    try {
      const response = await axios(
        {
          method: "PUT",
          url: `/api/resource-topics/${topicId}`,
          data: updatedData
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateResourceType = createAsyncThunk(
  "resource-type/update",
  async ({typeId, updatedData}: any, thunkAPI) => {
    try {
      const response = await axios(
        {
          method: "PUT",
          url: `/api/resource-types/${typeId}`,
          data: updatedData
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateResourceTypeModal = createAsyncThunk(
  "resource-type/update",
  async ({typeId, updatedData}: any, thunkAPI) => {
    try {
      const response = await axios(
        {
          method: "PUT",
          url: `/api/resource-types/modal/${typeId}`,
          data: updatedData
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const deleteResourceTopic = createAsyncThunk(
  "resource-topic/remove",
  async ({topicId}: any, thunkAPI) => {
    try {
      const response = await axios(
        {
          method: "DELETE",
          url: `/api/resource-topics/${topicId}`,
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const deleteResourceType = createAsyncThunk(
  "resource-type/remove",
  async ({typeId}: any, thunkAPI) => {
    try {
      const response = await axios(
        {
          method: "DELETE",
          url: `/api/resource-types/${typeId}`,
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getResourceTopicWithPaging = createAsyncThunk(
  "resource-topic-paging/get",
  async (query: any, thunkAPI) => {
    try {
      let url = `/api/resource-topics/with-paging?sortBy=${query.sortBy}&sortOrder=${query.sortOrder}&page=${query.page}&perPage=${query.perPage}`;
      const response = await axios({
        method: "GET",
        url: url,
      });
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const submitResourceType = createAsyncThunk(
  "resource-type/post",
  async (postData: any, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `/api/resource-types/request`,
        data: postData,
      });
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const saveResourceTopic = createAsyncThunk(
  "resource-topic/post",
  async (postData: any, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `/api/resource-topics/request`,
        data: postData,
      });
      let data = await response.data;
      if (response.status === 200) {
        getResourceTopic("11");
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const saveReviewReason = createAsyncThunk(
  "resource-comment/post",
  async ({ postData, id }: any, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `/api/content/${id}/comment`,
        data: postData,
      });
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const resourceApprove = createAsyncThunk(
  "resource-comment/post",
  async ({ id }: any, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `/api/content/${id}/approve`,
      });
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const cancelApproval = createAsyncThunk(
  "resource-comment/cancelApproval",
  async ({ id }: any, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `/api/content/${id}/cancel-approval`,
      });
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const publishContent = createAsyncThunk(
  "resource/publish",
  async ({ id }: any, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `/api/content/${id}/publish`,
      });
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const unpublishConent = createAsyncThunk(
  "resource/unpublish",
  async ({ id }: any, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `/api/content/${id}/unpublish`,
      });
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const addCurrentResources = createAsyncThunk(
  "resource/add-to-current-resources",
  async ({ id }: any, thunkAPI) => {
    try {
      const response = await axios({
        method: "put",
        url: `/api/content/${id}/add-to-current-resources`,
      });
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const removeCurrentResources = createAsyncThunk(
  "resource/remove-from-current-resources",
  async ({ id }: any, thunkAPI) => {
    try {
      const response = await axios({
        method: "put",
        url: `/api/content/${id}/remove-from-current-resources`,
      });
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const removeResourceFile = createAsyncThunk(
  "resource/delete-file",
  async (postData: any, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `/api/content/delete-file`,
        data: postData,
      });
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const resourceSlice = createSlice({
  name: "resource",
  initialState: {
    resource: {
      title: "",
      tags: [],
      resourceBody: "",
      publishForInSpecific: "",
      reviewDate: "",
      file: "",
      filePath: "",
      type: "",
      videoUrl: "",
      eventBody: {
        endsOn: "",
        fromDate: "",
        information: "",
        link: "",
        location: "",
        startsOn: "",
        title: "",
        toDate: "",
      },
      owner: {
        _id: "",
        name: "",
      },
      createdAt: "",
      updatedInfo: {
        by: {
          id: "",
          name: "",
        },
        at: "",
      },
      approvedInfo: {
        by: {
          id: "",
          name: "",
        },
        at: "",
      },
      isApproved: false,
      isSubmitted: false,
      isPublished: false,
      errorInfo: {
        by: {
          id: "",
          name: "",
        },
        at: "",
        message: ""
      },
      publishInfo: {
        by: {
          id: "",
          name: "",
        },
        at: "",
      },
      trust: {
        _id: ""
      },
      sharedTrust:[''],
      selectedApprover: {
        email: "",
        id: ""
      }
    },
    resourceList: { content: [], total: 0 },
    resourceTypes: [],
    resourceTopics: [],
    resourceTopic: { name : "" },
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createResource.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createResource.fulfilled, (state, action) => {
        state.resource = action.payload.data;
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(createResource.rejected, (state, action) => {
        // state.errorMessage = action.payload.message;
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(uploadFile.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        // state.errorMessage = action.payload.message;
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(updateResource.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateResource.fulfilled, (state, action) => {
        state.resource = action.payload.data;
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(updateResource.rejected, (state, action) => {
        // state.errorMessage = action.payload.message;
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(getResource.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getResource.fulfilled, (state, action) => {
        state.resource = action.payload.data;
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(getResource.rejected, (state, action) => {
        // state.errorMessage = action.payload.message;
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(getResourceList.pending, (state) => {
        state.isFetching = true;
        state.resourceList = { content: [], total: 0 };
      })
      .addCase(getResourceList.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.resourceList = action.payload.data;
      })
      .addCase(getResourceList.rejected, (state, action) => {
        // state.errorMessage = action.payload.message;
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(getResourceType.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getResourceType.fulfilled, (state, action) => {
        state.resourceTypes = action.payload.data;
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(getResourceType.rejected, (state, action) => {
        // state.errorMessage = action.payload.message;
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(getResourceTypeWithPaging.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getResourceTypeWithPaging.fulfilled, (state, action) => {
        state.resourceTypes = action.payload.data;
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(getResourceTypeWithPaging.rejected, (state, action) => {
        // state.errorMessage = action.payload.message;
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(getResourceTopicWithPaging.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getResourceTopicWithPaging.fulfilled, (state, action) => {
        state.resourceTopics = action.payload.data;
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(getResourceTopicWithPaging.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(getResourceTopic.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getResourceTopic.fulfilled, (state, action) => {
        state.resourceTopics = action.payload.data;
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(getResourceTopic.rejected, (state, action) => {
        // state.errorMessage = action.payload.message;
        state.isFetching = false;
        state.isError = true;
      })
      // .addCase(getResourceTopicById.pending, (state) => {
      //   state.isFetching = true;
      // })
      // .addCase(getResourceTopicById.fulfilled, (state, action) => {
      //   state.resourceTopic = action.payload.data;
      //   state.isFetching = false;
      //   state.isSuccess = true;
      // })
      // .addCase(getResourceTopicById.rejected, (state, action) => {
      //   state.isFetching = false;
      //   state.isError = true;
      // })
      .addCase(submitResourceType.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(submitResourceType.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(submitResourceType.rejected, (state, action) => {
        // state.errorMessage = action.payload.message;
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(saveResourceTopic.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(saveResourceTopic.fulfilled, (state, action) => {
        // state.resourceTopic = [...state.resourceTopic, action.payload.data];
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(saveResourceTopic.rejected, (state, action) => {
        // state.errorMessage = action.payload.message;
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(saveReviewReason.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(saveReviewReason.fulfilled, (state, action) => {
        // state.resourceTopic = [...state.resourceTopic, action.payload.data];
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(saveReviewReason.rejected, (state, action) => {
        // state.errorMessage = action.payload.message;
        state.isFetching = false;
        state.isError = true;
      });
      // .addCase(getResourceTopicById.rejected, (state, action) => {
      //   console.log('payload', action.payload)
      //   // state.resourceTopic = action.payload.message;
      //   state.isFetching = false;
      //   state.isError = true;
      // });
  },
});

export const { clearState } = resourceSlice.actions;

export const resourceSelector = (state: RootState) => state.resource;
export const resourceListSelector = (state: RootState) =>
  state.resource.resourceList;
export const resourceTypesSelector = (state: RootState) =>
  state.resource.resourceTypes;
export const resourceTopicsSelector = (state: RootState) =>
  state.resource.resourceTopics;
export const resourceTopic = (state: RootState) => state.resource.resourceTopic

export default resourceSlice.reducer;
