import React, { useState } from 'react';
import { useHistory, NavLink, Link } from 'react-router-dom';
import jwt from 'jwt-decode';
import { Nav, NavDropdown } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { useAppDispatch } from '../app/hooks';
import { logOutUser } from '../features/Auth/AuthSlice';
import { Constants } from '../global/Constants';

import Logo from '../assets/images/logo.svg';
import Profile from '../assets/images/Profileicon.svg';

import './styles/Header.scss';
import { MiddlewareArray } from '@reduxjs/toolkit';

const Header = () => {
  const tabIndex = 0;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const onNavClick = (view: string) => {
    history.push(`${view}`);
  };

  const path = window.location.pathname;
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);

  const jwtDecode: any = jwt(localStorage.getItem('token') || '');
  const shortenRole: Array<string> = jwtDecode.roles || [];
  const username: any = jwtDecode.name || '';
  const local_name = localStorage.getItem('username') || '';

  const userRoles = Constants.USER_ROLES;
  const logout = async () => {
    const token = localStorage.getItem('token');
    await fetch(`${process.env.REACT_APP_SERVICE_PHR}/api/user/logout`, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    });
    dispatch(logOutUser());
    history.push(`/login`);
  };

  return (
    <>
      <div className='primary-header'>
        <div>
          <Link className='header-logo' to='/'>
            <img src={Logo} alt='Triangle' />
          </Link>
        </div>
        <div className='secondary-header'>
          <div>
            <div className='account-blk'>
              <div>
                <p tabIndex={tabIndex}>
                  {local_name
                    ? local_name.charAt(0).toUpperCase() + local_name.slice(1)
                    : username.charAt(0).toUpperCase() + username.slice(1)}
                </p>
              </div>
              <div
                className={`p-0.5 ${
                  path === '/profile' || path === '/account'
                    ? 'active-profile'
                    : 'inactive-profile'
                }`}
                // ref={wrapperRef}
              >
                <img
                  src={Profile}
                  alt='profile'
                  onClick={() => setShowProfileDropdown(!showProfileDropdown)}
                  aria-hidden='true'
                ></img>
                {showProfileDropdown && (
                  <div>
                    <div className='menu-dropdown'></div>
                    <div>
                      <div
                        className='sub-menu-dropdown'
                        onClick={() => {
                          setShowProfileDropdown(false);
                        }}
                      >
                        <Link to='/profile'>Account</Link>

                        <div onClick={() => logout()}>Logout</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <header>
        {userRoles && shortenRole ? (
          <div className='primaryNav '>
            <ul className='d-md-flex justify-content-center'>
              {shortenRole.includes('L1') ? (
                <li role='presentation'>
                  <Nav.Item className=''>
                    <NavLink
                      to='/trust'
                      activeClassName='activeMenu'
                      className={
                        isMobile ? 'nav-items nav-items-m-font' : 'nav-items'
                      }
                    >
                      Trust management
                    </NavLink>
                    {/* <div className="header-mark"> </div> */}
                  </Nav.Item>
                </li>
              ) : (
                ''
              )}
              {shortenRole.includes('SA') ? (
                <li role='presentation'>
                  <Nav.Item className=''>
                    <NavLink
                      to='/systemAdmin'
                      activeClassName='activeMenu'
                      className={
                        isMobile ? 'nav-items nav-items-m-font' : 'nav-items'
                      }
                    >
                      System Admin management
                    </NavLink>
                    {/* <div className="header-mark"> </div> */}
                  </Nav.Item>
                </li>
              ) : (
                ''
              )}
              {shortenRole.includes('TA') ? (
                <li role='presentation'>
                  <Nav.Item className=''>
                    <NavLink
                      to='/user-management'
                      activeClassName='activeMenu'
                      className={
                        isMobile ? 'nav-items nav-items-m-font' : 'nav-items'
                      }
                    >
                      User management
                    </NavLink>
                    {/* <div className="header-mark"> </div> */}
                  </Nav.Item>
                </li>
              ) : (
                ''
              )}
              {shortenRole.includes('TA') ||
              shortenRole.includes('CM') ||
              shortenRole.includes('CA') ||
              shortenRole.includes('L1') ? (
                <li role='presentation'>
                  <Nav.Item className=''>
                    <NavLink
                      exact
                      to='/resource'
                      activeClassName='activeMenu'
                      className={
                        isMobile ? 'nav-items nav-items-m-font' : 'nav-items'
                      }
                    >
                      Current resource list
                    </NavLink>
                    {/* <div className="header-mark"> </div> */}
                  </Nav.Item>
                </li>
              ) : (
                ''
              )}
              {shortenRole.includes('CM') ? (
                <li role='presentation'>
                  <Nav.Item className=''>
                    <NavLink
                      exact
                      to='/resource/create'
                      activeClassName='activeMenu'
                      className={
                        isMobile ? 'nav-items nav-items-m-font' : 'nav-items'
                      }
                    >
                      Create new resource
                    </NavLink>
                    {/* <div className="header-mark"> </div> */}
                  </Nav.Item>
                </li>
              ) : (
                ''
              )}
              {shortenRole.includes('TA') ||
              shortenRole.includes('CM') ||
              shortenRole.includes('CA') ? (
                <li role='presentation'>
                  <Nav.Item className=''>
                    <NavLink
                      to='/notification-center'
                      activeClassName='activeMenu'
                      className={
                        isMobile ? 'nav-items nav-items-m-font' : 'nav-items'
                      }
                    >
                      My notifications
                    </NavLink>
                    {/* <div className="header-mark"> </div> */}
                  </Nav.Item>
                </li>
              ) : (
                ''
              )}
              {shortenRole.includes('TA') ? (
                <li role='presentation'>
                  <Nav.Item className=''>
                    <NavLink
                      to='/resource-library'
                      activeClassName='activeMenu'
                      className={
                        isMobile ? 'nav-items nav-items-m-font' : 'nav-items'
                      }
                    >
                      Resource library
                    </NavLink>
                    {/* <div className="header-mark"> </div> */}
                  </Nav.Item>
                </li>
              ) : (
                ''
              )}
            </ul>
          </div>
        ) : (
          ''
        )}
      </header>
    </>
  );
};

export default Header;
