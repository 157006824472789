import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { authSlice } from '../features/Auth/AuthSlice';
import { resourceSlice } from '../features/Resource/ResourceSlice';
import { trustSlice } from '../features/Trust/TrustSlice';
import { userSlice } from '../features/User/UserSlice';
import { notificationSlice } from '../features/Notification/Notification';
import { resourceLibrarySlice } from '../features/ResourceLibrary/ResourceLibrary';
import { resourceTopicSlice } from '../features/ResourceTopic/ResourceTopicSlice';
import { resourceTypeSlice } from '../features/ResourceType/ResourceTypeSlice';
import { tagListSlice } from '../features/TagList/TagListSlice';
import { level1UserSlice} from '../features/Level1User/level1UserSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    resource: resourceSlice.reducer,
    trust: trustSlice.reducer,
    user: userSlice.reducer,
    notification: notificationSlice.reducer,
    resourceLibrary: resourceLibrarySlice.reducer,
    resourceTopic: resourceTopicSlice.reducer,
    resourceType: resourceTypeSlice.reducer,
    tagList: tagListSlice.reducer,
    level1User: level1UserSlice.reducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
