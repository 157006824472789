import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';
import jwt from "jwt-decode";


export const createUser = createAsyncThunk(
  "user/create",
  async (postData: any, thunkAPI) => {
    try {
      const response = await axios(
        {
          method: "POST",
          url: `/api/trust/user`,
          data: postData
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getUserProfile = createAsyncThunk(
  "user/profile",
  async (query: any, thunkAPI) => {
    try {      
      
      const response = await axios(
        {
          method: "get",
          url: `/api/user/profile`,
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/update",
  async ({userId, updatedData}: any, thunkAPI) => {
    try {
      const response = await axios(
        {
          method: "PUT",
          url: `/api/user/${userId}`,
          data: updatedData
        }
      );
      let data = await response.data;
      console.log("🚀 ~ file: UserSlice.ts ~ line 67 ~ response", response)
      if (response.status === 200) {
        localStorage.setItem("username", (response.data.data.name + ' ' + (response.data.data.lastName || '')).trim());
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const removeUser = createAsyncThunk(
  "user/remove",
  async (userId: any, thunkAPI) => {
    try {
      const response = await axios(
        {
          method: "DELETE",
          url: `/api/user/${userId}`,
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getUserList = createAsyncThunk(
  "user/getlist",
  async (query: any, thunkAPI) => {
    try {
      const jwtDecode: any = jwt(localStorage.getItem("token") || "");
      let trustId = jwtDecode.trustId;
      let getAllUserUrl = `/api/user/getAll`;
      if(trustId !== "undefined") {
        getAllUserUrl = `/api/trust/${trustId}/users`;
      }
      let url = `${getAllUserUrl}?sortBy=${query.sortBy}&sortOrder=${query.sortOrder}&page=${query.page}&perPage=${query.perPage}`;
      if (query.searchString) {
        query.searchString =   (query.searchString).replace(/^\s+/g, '');
        const searchTxt = encodeURIComponent(query.searchString);
        url = `${url}&searchString=${searchTxt}`;
      }
      if (query.filter && Object.keys(query.filter).length > 0) {
        url = `${url}&filter=${JSON.stringify(query.filter)}`;
      }
      const response = await axios(
        {
          method: "GET",
          url: url,
          data: query
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e);
      thunkAPI.rejectWithValue(e);
    }
  }
);


export const getUserListRes = createAsyncThunk(
  "user/getlist",
  async (query: any, thunkAPI) => {
    try {
      const jwtDecode: any = jwt(localStorage.getItem("token") || "");
      let trustId = jwtDecode.trustId;
      let getAllUserUrl = `/api/user/getAll`;
      if(trustId !== "undefined") {
        getAllUserUrl = `/api/trust/${trustId}/content`;
      }
      let url = `${getAllUserUrl}?sortBy=${query.sortBy}&sortOrder=${query.sortOrder}&page=${query.page}&perPage=${query.perPage}`;
      if (query.searchString) {
        url = `${url}&searchString=${query.searchString}`;
      }
      if (query.filter && Object.keys(query.filter).length > 0) {
        url = `${url}&filter=${JSON.stringify(query.filter)}`;
      }
      const response = await axios(
        {
          method: "GET",
          url: url,
          data: query
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e);
      thunkAPI.rejectWithValue(e);
    }
  }
);

export const getAuthorList = createAsyncThunk(
  "user/getauthorlist",
  async (query: any, thunkAPI) => {
    try {
      let getAllUserUrl = `/api/user/getAuthors`;

      const response = await axios(
        {
          method: "GET",
          url: getAllUserUrl
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e);
      thunkAPI.rejectWithValue(e);
    }
  }
);

export const sendResetLinkToUser = createAsyncThunk(
  "user/sendResetLink",
  async (userId: any, thunkAPI) => {
    try {
      const response = await axios(
        {
          method: "POST",
          url: `/api/user/${userId}/reset`,
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {},
    userList: {users: [], total: 0},
    authorList:{users: [], total: 0},
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getUserList.pending, (state) => {
      state.isFetching = true;
      state.userList = {users: [], total: 0};
    })
    .addCase(getUserList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.userList = action.payload.data;
    })
    .addCase(getAuthorList.pending, (state) => {
      state.isFetching = true;
    })
    .addCase(getAuthorList.fulfilled, (state, action) => {
      state.authorList = action.payload.data;
      state.isFetching = false;
      state.isSuccess = true;
    })
    .addCase(getAuthorList.rejected, (state, action) => {
      // state.errorMessage = action.payload.message;//
      state.isFetching = false;
      state.isError = true;
    })
    .addCase(getUserList.rejected, (state, action) => {
      // state.errorMessage = action.payload.message;
      state.isFetching = false;
      state.isError = true;
    })
    .addCase(createUser.pending, (state) => {
      state.isFetching = true;
    })
    .addCase(createUser.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.user = action.payload.data;
    })
    .addCase(createUser.rejected, (state, action) => {
      // state.errorMessage = action.payload.message;
      state.isFetching = false;
      state.isError = true;
    })
    .addCase(removeUser.pending, (state) => {
      state.isFetching = true;
    })
    .addCase(removeUser.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isSuccess = true;
    })
    .addCase(removeUser.rejected, (state, action) => {
      // state.errorMessage = action.payload.message;
      state.isFetching = false;
      state.isError = true;
    })
    .addCase(sendResetLinkToUser.pending, (state) => {
      state.isFetching = true;
    })
    .addCase(sendResetLinkToUser.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isSuccess = true;
    })
    .addCase(sendResetLinkToUser.rejected, (state, action) => {
      // state.errorMessage = action.payload.message;
      state.isFetching = false;
      state.isError = true;
    });
  },
});

export const { clearState } = userSlice.actions;

export const userSelector = (state: RootState) => state.user;
export const userListSelector = (state: RootState) => state.user.userList;
export const authorSelector = (state: RootState) => state.user.authorList;

export default userSlice.reducer;