import React, { Suspense, useEffect } from 'react';
import { Switch, Route, Redirect, Router } from 'react-router-dom';

import { PrivateRoute } from './PrivateRoute';
// import Login from "../pages/Login/Login";
// import Resource from "../pages/Resource/Resource";
// import UserManagement from "../pages/UserManagement/UserManagement";
// import Trust from "../pages/Trust/TrustList";
// import Notification from "../pages/Notification/Notification";
// import NotificationView from "../pages/Notification/NotificationView";
// import NoAccess from "../pages/NoAccess/NoAccess";
// import ResourceLibrary from "../pages/ResourceLibrary/ResourceLibrary";
// import ResourceType from "../pages/ResourceType/ResourceType";
// import ResourceTopic from "../pages/ResourceTopic/ResourceTopic";
// import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
// import ResetPassword from "../pages/ForgotPassword/ResetPassword";
// import ResourceTopicView from "../pages/ResourceTopic/ResourceTopicView";
// import ResourceTypeView from "../pages/ResourceType/ResourceTypeView";
import { Profile } from '../pages/Account/Profile';
import { ResourcesApi } from '../pages/ResourcesApi/ResourcesApi';

import Loader from '../components/loading/Loading';
import Level1User from '../pages/Level1User/Level1User';

const Login = React.lazy(() => import('../pages/Login/Login'));
const ForgotPassword = React.lazy(
  () => import('../pages/ForgotPassword/ForgotPassword')
);
const ResetPassword = React.lazy(
  () => import('../pages/ForgotPassword/ResetPassword')
);
const Resource = React.lazy(() => import('../pages/Resource/Resource'));
const UserManagement = React.lazy(
  () => import('../pages/UserManagement/UserManagement')
);
const Trust = React.lazy(() => import('../pages/Trust/TrustList'));
const Notification = React.lazy(
  () => import('../pages/Notification/Notification')
);
const NotificationView = React.lazy(
  () => import('../pages/Notification/NotificationView')
);
const NoAccess = React.lazy(() => import('../pages/NoAccess/NoAccess'));
const ResourceLibrary = React.lazy(
  () => import('../pages/ResourceLibrary/ResourceLibrary')
);
const ResourceType = React.lazy(
  () => import('../pages/ResourceTopic/ResourceTopic')
);
const ResourceTopic = React.lazy(
  () => import('../pages/ResourceTopic/ResourceTopic')
);
const ResourceTopicView = React.lazy(
  () => import('../pages/ResourceTopic/ResourceTopicView')
);
const ResourceTypeView = React.lazy(
  () => import('../pages/ResourceType/ResourceTypeView')
);

export const AppRoutes = () => {
  const [showElement, setShowElement] = React.useState(true);
  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
    }, 1000);
  }, []);

  return (
    <Suspense fallback={<Loader status={showElement} />}>
      <Switch>
        <Route exact path='/' component={Login} />
        <Route exact path='/login' component={Login} />
        <Route exact path='/forgot-password' component={ForgotPassword} />
        <Route exact path='/reset-password' component={ResetPassword} />
        <Route exact path='/set-password' component={ResetPassword} />
        <Route exact path='/endpoint' component={ResourcesApi} />
        <PrivateRoute
          exact
          path='/profile'
          component={Profile}
          accessRoles={['CM', 'CA', 'TA', 'L1']}
        />
        <PrivateRoute
          path='/resource'
          component={Resource}
          accessRoles={['CM', 'CA', 'TA', 'L1']}
        />
        <PrivateRoute
          exact
          path='/user-management'
          component={UserManagement}
          accessRoles={['TA']}
        />
        <PrivateRoute
          exact
          path='/trust'
          component={Trust}
          accessRoles={['L1']}
        />
        <PrivateRoute
          exact
          path='/systemAdmin'
          component={Level1User}
          accessRoles={['SA']}
        />
        <PrivateRoute
          exact
          path='/notification-center'
          component={Notification}
          accessRoles={['CM', 'CA', 'TA']}
        />
        <PrivateRoute
          // exact
          path='/notification/view/:id'
          component={NotificationView}
          accessRoles={['CM', 'CA', 'TA']}
        />
        <PrivateRoute
          exact
          path='/resource-library'
          component={ResourceLibrary}
          accessRoles={['CM', 'CA', 'TA']}
        />
        <PrivateRoute
          exact
          path='/resource-type'
          component={ResourceType}
          accessRoles={['SA', 'TA']}
        />
        <PrivateRoute
          exact
          path='/resource-topic'
          component={ResourceTopic}
          accessRoles={['SA', 'TA']}
        />
        <PrivateRoute
          // exact
          path='/resource-topics/view/:id'
          component={ResourceTopicView}
          accessRoles={['CM', 'CA', 'TA']}
        />
        <PrivateRoute
          // exact
          path='/resource-types/view/:id'
          component={ResourceTypeView}
          accessRoles={['TA']}
        />
        <Route exact path='/no-access' component={NoAccess} />
      </Switch>
    </Suspense>
  );
};
