import React from "react";
import toast from "react-hot-toast";

import toastClose from "../assets/images/toast-close.svg";

import "./styles/Toast.scss";

export const Toast = ({ ...props }: any): JSX.Element => {
  return (
    <div className="custom-toast">
      <div className="title d-flex align-items-center justify-content-between">
        <div className="">{props.title}</div>
        <img
          className="cursor-pointer"
          src={toastClose}
          alt="close"
          onClick={() => toast.remove()}
        />
      </div>
      <div className="content">{props.content}</div>
    </div>
  );
};

export default Toast;
